import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoES } from "../components/seo";
import { translations } from "../locales/wedding.js";
import EventsTypes from "../components/eventsTypes";
import background from "../assets/img/eventsTypes/wedding.svg";

const Wedding = () => {
  return (
    <Layout page="wedding" language="es">
      <main className="container">
        <EventsTypes
          background={background}
          text={translations.es.text}
          title={translations.es.title}
          textButton={translations.es.button}
          language={"es"}
        />
      </main>
    </Layout>
  );
};

export default Wedding;

export const Head = () => <SeoES title="Invitaciones digitales de boda Online | Diseña y envía tus invitaciones con Kolibird" description="Con Kolibird, crea y envía tus invitaciones de boda de manera digital. Personaliza tus diseños y sorprende a tus invitados con una alternativa ecológica y práctica. ¡Regístrate ahora en Kolibird y comienza a diseñar tus invitaciones de boda!" hrefLangs={[{href: "https://kolibird.app/invitaciones-digitales-boda/", lang: "es"}, {href: "https://kolibird.app/en/digital-wedding-invitations/", lang: "en"}]}/>;
